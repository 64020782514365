import { Injectable } from '@angular/core';

const GFNW_BASE_URL = 'gfnw.klaricore.com';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentCheckupService {
  private isGfnwEnv: boolean;

  constructor() {
    const currentOrigin = window.location.origin;

    this.isGfnwEnv = currentOrigin.includes(GFNW_BASE_URL);
  }

  /**
   * Check if the environment is gfnw.
   * @returns {boolean} True if it's gfnw environment.
   */
  isGfnw(): boolean {
    return this.isGfnwEnv;
  }

  /**
   * Get the correct redirect path after login.
   * @returns {string} The path to redirect to after login.
   */
  getLoginRedirectPath(queryRedirect?: string): string {
    if (queryRedirect) {
      return queryRedirect; // Use the redirect from query params if available
    }

    const redirectPath = this.isGfnwEnv ? '/slm/compliance-report' : '/welcome';

    return redirectPath;
  }
}
