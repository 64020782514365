<div class="layout-topbar">
  <img src="assets/branding/logo.svg" alt="brand-logo-no-text" role="button" height="44" id="SEL_BRAND_LOGO"
    (click)="redirectToHome()" />

  <div class="layout-topbar-menu">
    <!--Global search-->
    <app-search></app-search>
    <!-- language toggle icon -->
    <app-language-toggle class="my-auto ms-4 me-3"></app-language-toggle>

    <p-divider layout="vertical"></p-divider>

    <!-- admin icon -->
    <!-- <button
      class="p-link"
      pTooltip="{{ 'HEADER.ADMIN' | translate }}"
      tooltipPosition="bottom"
    >
      <i class="pi pi-shield icon-size-color topbar-icon"></i>
    </button> -->

    <!-- legal icon -->
    <button class="p-link me-4" pTooltip="{{ 'HEADER.LEGAL' | translate }}" tooltipPosition="bottom"
      (click)="goToLegal()">
      <i class="material-symbols-outlined icon-size-color topbar-icon">balance</i>
    </button>

    <!-- about icon -->
    <button class="p-link" (click)="viewAboutDetails()" pTooltip="{{ 'HEADER.ABOUT' | translate }}"
      tooltipPosition="bottom">
      <i class="pi pi-info-circle icon-size-color topbar-icon"></i>
    </button>

    <!-- system settings icon -->
    <button class="p-link mx-4" (click)="redirectToSettings()" pTooltip="{{ 'HEADER.SETTINGS' | translate }}"
      tooltipPosition="bottom">
      <i class="pi pi-cog icon-size-color topbar-icon"></i>
    </button>

    <!-- help center icon-->
    <button class="p-link" (click)="goToHelpCenter()" pTooltip="{{ 'HEADER.HELP_CENTER' | translate }}"
      tooltipPosition="bottom">
      <i class="pi pi-question-circle icon-size-color topbar-icon"></i>
    </button>

    <!-- favorites menu icon -->
    <app-favorites class="my-auto ms-4"></app-favorites>

    <p-divider layout="vertical"></p-divider>

    <!-- user initials -->
    <button class="p-link" (click)="menu.toggle($event)">
      <p-avatar class="uppercase" [label]="
          credentialsService.user?.given_name?.split('', 1) +
          credentialsService.user?.family_name?.split('', 1)
        " shape="circle" size="large">
      </p-avatar>
      <i class="pi ml-2 pi-angle-down user-initial-caret"></i>
    </button>

    <p-menu #menu [popup]="true" [model]="logoutMenu" appendTo="body"></p-menu>
  </div>
</div>

<!-- logout confirmation -->
<p-sidebar [(visible)]="isViewEnabled" position="right" styleClass="p-sidebar-md" [dismissible]="false"
  [closeOnEscape]="false">
  <app-confirmation (closeClicked)="isViewEnabled = $event"></app-confirmation>
</p-sidebar>

<!-- About right sidebar -->
<p-sidebar [(visible)]="isAboutEnabled" position="right" styleClass="p-sidebar-md">
  <app-about *ngIf="isAboutEnabled" (closeClicked)="isAboutEnabled = $event"></app-about>
</p-sidebar>


<p-sidebar [(visible)]="canChangePassword" position="right" styleClass="p-sidebar-md" [dismissible]="false"
  [closeOnEscape]="false" *ngIf="canChangePassword">
  <app-change-password *ngIf="canChangePassword" (closeClicked)="canChangePassword = false"></app-change-password>
</p-sidebar>