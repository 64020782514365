import { Component, NgZone, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { LoaderService } from '@app/layout/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  showLoader = false;

  constructor(private loaderService: LoaderService, private ngZone: NgZone, private router: Router) {}

  ngOnInit(): void {
    // Define excluded routes and contexts
    this.loaderService.setExcludedRoutes(['/reports/view-reports', '/data/calculations']);
    // Listen to loading state changes
    this.loaderService.loading$.subscribe((val: boolean) => {
      this.ngZone.run(() => {
        const currentUrl = this.router.url;

        if (this.loaderService.isRouteExcluded(currentUrl) || this.loaderService.isContextExcluded('global-search')) {
          this.showLoader = false;
        } else {
          this.showLoader = val;
        }
      });
    });

    // Listen to route changes to dynamically check exclusions
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd) // Type guard to narrow the type
      )
      .subscribe(event => {
        const currentUrl = event.urlAfterRedirects; // TypeScript now knows event is NavigationEnd

        if (this.loaderService.isRouteExcluded(currentUrl)) {
          this.loaderService.setLoading(false); // Stop the loader for excluded routes
        }
      });
  }
}
