import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';

import { ErrorBlockService } from '@app/shared/services/error-block.service';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';
import { MetricsService } from '@app/slm/services/metrics.service';

@Component({
  selector: 'app-view-metric',
  templateUrl: './view-metric.component.html',
  styleUrls: ['./view-metric.component.scss'],
})
export class ViewMetricComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild('op', { static: false }) overlayPanel: any;

  @ViewChild('paginationReset') paginationReset!: Table;

  metricSubscription!: Subscription;

  viewData: any;

  viewVersionList: any = [];

  viewUdfList: any;

  versionUdfList: any;

  selectedVersion: any;

  filteredVersion: any = [];

  // errorMsgList: any = [];

  metricVersionObjectFields: any;

  createMetricForm!: FormGroup;

  metricUDFData: any;

  viewMetricParamValues: any;

  statusList: any = [
    { label: 'Draft', class: 'warning-chip' },
    { label: 'Published', class: 'success-chip' },
    { label: 'Revoked', class: 'danger-chip' },
  ];

  selectedStatusFilter: any = { Draft: false, Published: false, Revoked: false };

  isEditVersionEnabled!: boolean;

  isAddVersionEnabled!: boolean;

  isEditActive!: boolean;

  isEditUDFEnabled!: boolean;

  constructor(
    private metricService: MetricsService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public metricPermission: MetricPermissionService,
    private errorBlockService: ErrorBlockService
  ) {}

  ngOnInit(): void {
    this.createMetricForm = this.formBuilder.group({
      versionName: [''],
      statusValue: [''],
      target: [''],
      expected: [''],
      startDate: [''],
      endDate: [''],
      metricVersionStatement: [null],
      statusFilter: new FormArray([]),
    });

    this.addCheckboxes();
    // TODOS to check from edit so that it can be minimized
    this.metricSubscription = this.metricService.viewData.subscribe((res: any) => {
      if (res) {
        this.viewData = [];
        this.viewVersionList = [];
        this.createMetricForm.reset();
        this.getViewMetric(res);
      }
    });

    this.metricService.editVersionInfo.subscribe((res: any) => {
      this.isEditVersionEnabled = res || false;
      this.isEditUDFEnabled = res || false;
    });
    this.metricService.addVersionInfo.subscribe((res: any) => {
      this.isAddVersionEnabled = res || false;
    });

    // Setting intial value as true so that, edit can be set
    this.isEditActive = true;
  }

  getViewMetric(metricid: any): void {
    this.metricService.getViewMetric(metricid).subscribe((res: any) => {
      if (res) {
        this.viewData = res;
        this.viewVersionList = this.viewData.metricVersionList;
        this.viewUdfList = this.viewData.objectFieldData;

        this.viewMetricParamValues = this.viewData.metricParamValues || [];

        this.patchValue(this.viewVersionList[0]);

        [this.selectedVersion] = this.viewVersionList;

        this.filteredVersion = this.viewVersionList;

        Object.keys(this.viewData).forEach(key => {
          this.viewData[key] = this.viewData[key] ? this.viewData[key] : 'N/A';
        });
      }
    });
  }

  // In your component TypeScript file
  hasPublishedOrRevokedVersion(): boolean {
    return this.viewVersionList.some(
      (metricVersion: any) => metricVersion.statusValue === 'Published' || metricVersion.statusValue === 'Revoked'
    );
  }

  patchValue(data: any): void {
    this.createMetricForm.patchValue({
      versionName: data.versionName ? data.versionName : 'N/A',
      statusValue: data.statusValue ? data.statusValue : 'N/A',
      target:
        this.viewData.conditionName && data.formattedTarget && data.formattedTarget !== undefined
          ? `${this.viewData.conditionName} ${data.formattedTarget}`
          : 'N/A',
      expected:
        this.viewData.conditionName && data.formattedExpected && data.formattedExpected !== undefined
          ? `${this.viewData.conditionName} ${data.formattedExpected}`
          : 'N/A',
      startDate: data.startDate ? new Date(data.startDate) : 'N/A',
      endDate: data.endDate ? new Date(data.endDate) : 'N/A',
      metricVersionStatement: data.metricVersionStatement ? data.metricVersionStatement : 'N/A',
    });

    this.versionUdfList = data.objectFieldData;
  }

  applyStatusFilter(event: any, field: string): void {
    Object.keys(this.selectedStatusFilter).forEach(key => {
      if (key === field) {
        this.selectedStatusFilter[key] = event.checked;
      }
    });

    // filter the versions from the list
    const filterList: any = [];

    this.paginationReset?.reset();

    this.viewVersionList.forEach((status: any) => {
      if (this.selectedStatusFilter[status.statusValue]) {
        filterList.push(status);
      }
    });

    // check if no filter selected show initial list
    const noFilterSelected = Object.values(this.selectedStatusFilter).every(v => !v);

    if (noFilterSelected) {
      this.filteredVersion = this.viewVersionList;
    } else {
      this.filteredVersion = filterList;
    }
  }

  onClosePanel(event: any): void {
    this.selectedVersion = event.data;
    this.patchValue(this.selectedVersion);
    this.overlayPanel.hide();
    this.errorBlockService.clearErrors();
  }

  closeViewMetric(): void {
    this.viewData = [];
    this.viewVersionList = [];
    this.filteredVersion = [];
    this.selectedStatusFilter = {};
    this.createMetricForm.reset();
    this.closeClicked.emit(false);
    this.metricService.toogleSidebar(false);
    this.errorBlockService.clearErrors();
  }

  ngOnDestroy(): void {
    this.metricSubscription.unsubscribe();
  }

  // Encapuslating  edit and Add  in view by handling the variable
  triggerEditVersion(): void {
    this.isEditVersionEnabled = !this.isEditVersionEnabled;

    const editVersionData = {
      viewData: this.viewData,
      selectedVersion: this.selectedVersion,
      versionList: this.viewData.metricVersionList,
      isEditUDFEnabled: !this.hasPublishedOrRevokedVersion(),
    };

    this.metricService.getSelectedVersion(editVersionData);
    this.errorBlockService.clearErrors();
  }

  triggerAddVersion(): void {
    this.isAddVersionEnabled = !this.isAddVersionEnabled;

    const editVersionData = {
      viewData: this.viewData,
      selectedVersion: this.selectedVersion,
      versionList: this.viewData.metricVersionList,
    };

    this.metricService.getSelectedVersion(editVersionData);
    this.errorBlockService.clearErrors();
  }

  // Publish and revoke
  cofirmPublish(event: Event): void {
    this.errorBlockService.clearErrors();

    if (!this.createMetricForm.get('startDate')?.value || !this.createMetricForm.get('endDate')?.value) {
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      this.errorBlockService.addError(msg.message);
      this.createMetricForm.markAllAsTouched();
    } else if (this.createMetricForm.invalid) {
      this.createMetricForm.markAllAsTouched();
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion(this.viewData.id);
        },
      });
    }
  }

  revokeVersion(metricId: number): void {
    this.errorBlockService.clearErrors();

    const versionId = this.createMetricForm.get('versionName')?.value?.versionId
      ? this.createMetricForm.get('versionName')?.value?.versionId
      : this.selectedVersion.versionId;

    const payload = {
      id: versionId,
    };

    this.metricService.revokeVersion(metricId, versionId, payload).subscribe(
      (res: any) => {
        if (res) {
          this.metricService.getCreatedMetricData({ res });
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_REVOKE', {
              metric: this.viewData.metricName,
              version: res.versionName,
            }),
          });
          this.closeViewMetric();
          this.metricService.toogleSidebar(false);
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          err.forEach((msg: any) => {
            this.errorBlockService.addError(msg.message);
          });
          // this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmRevoke(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      icon: 'pi pi-exclamation-triangle',
      message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_REVOKE'),
      acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
      rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.revokeVersion(this.viewData.id);
      },
    });
  }

  publishVersion(metricId: number): void {
    this.errorBlockService.clearErrors();
    const versionId = this.createMetricForm.get('versionName')?.value?.versionId
      ? this.createMetricForm.get('versionName')?.value?.versionId
      : this.selectedVersion.versionId;
    const payload = {
      versionId,
    };

    this.metricService.publishVersion(metricId, payload, false).subscribe(
      (res: any) => {
        if (res) {
          this.metricService.getCreatedMetricData({ res });
          this.messageService.add({
            severity: 'success',

            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_PUBLISH', {
              metric: this.viewData.metricName,
              version: res.versionName,
            }),
          });
          this.closeViewMetric();
          this.metricService.toogleSidebar(false);
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          err.forEach((msg: any) => {
            this.errorBlockService.addError(msg.message);
          });
          // this.errorMsgList = errorList;
        }
      }
    );
  }

  private addCheckboxes(): void {
    this.statusList.map(() => {
      const control = new FormControl();

      return (this.createMetricForm.get('statusFilter') as FormArray).push(control);
    });
  }
}
