import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './authentication/components/error/error.component';
import { NegateAuthenticationGuard } from './core/guards/negate-authentication.guard';
import { ShellService } from './shell/services/shell.service';
import { EnvironmentCheckupService } from '@app/core/resources/environment-checkup.service';
import { environment } from '@env/environment';

function loadWelcomeModule(): any {
  // Get the EnvironmentCheckupService instance directly via DI
  const envService = new EnvironmentCheckupService();

  if (envService.isGfnw()) {
    // If it is a GFNW environment, load the Dashboard module
    return import('./slm/slm.module').then(m => m.SLMModule);
  }
  if (environment.name === 'demo') {
    // If it's demo , load the dashobard module
    return import('./dashboard/dashboard.module').then(m => m.DashboardModule);
  }

  return import('./welcome/welcome.module').then(m => m.WelcomeModule);
}
const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  ShellService.headerSidebarRoutes([
    {
      path: 'welcome',
      loadChildren: () => loadWelcomeModule(),
    },
    {
      path: 'slm',
      data: {
        breadcrumb: 'MODULES.SLM.SLM',
      },
      loadChildren: () => import('./slm/slm.module').then(m => m.SLMModule),
    },
    {
      path: 'reports',
      data: {
        breadcrumb: 'MODULES.REPORTS.REPORTS',
      },
      loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
    },
    {
      path: 'data',
      data: {
        breadcrumb: 'MODULES.DATA.DATA',
      },
      loadChildren: () => import('./data/data.module').then(m => m.DataModule),
    },
    {
      path: 'distributions',
      data: {
        breadcrumb: 'BREADCRUMBS.DISTRIBUTIONS',
      },
      loadChildren: () => import('./distributions/distributions.module').then(m => m.DistributionsModule),
    },
    {
      path: 'settings',
      data: {
        breadcrumb: 'BREADCRUMBS.SYSTEM_SETTINGS',
      },
      loadChildren: () => import('./system-settings/system-settings.module').then(m => m.SystemSettingsModule),
    },
    {
      path: 'highcharts',
      data: {
        breadcrumb: 'Highcharts',
      },
      loadChildren: () => import('./highcharts/highcharts.module').then(m => m.HighchartsModule),
    },
  ]),
  {
    path: 'auth',
    canActivate: [NegateAuthenticationGuard],
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'not-found',
    component: ErrorComponent,
  },
  {
    path: 'forbidden',
    component: ErrorComponent,
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
