import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, tap, catchError, throwError } from 'rxjs';

import { LoaderService } from '@app/layout/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.setLoading(true);

    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.setLoading(false); // Hide loader on success
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.setLoading(false); // Ensure the loader stops even on error

        return throwError(() => error); //  rethrow errors
      })
    );
  }
}
