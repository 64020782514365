import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

import { LayoutService } from '../../services/layout.service';
import { EnvironmentCheckupService } from '@app/core/resources/environment-checkup.service';
import { CredentialsService } from '@app/core/services/credentials.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  @Output() clearSubmenu = new EventEmitter<void>();

  open = false;

  isViewEnabled = false;

  isAboutEnabled = false;

  canChangePassword = false;

  logoutMenu: MenuItem[] = [
    {
      label: 'Change Password',
      icon: 'pi pi-lock',
      id: 'CHANGE_PASSWORD',
      command: () => {
        this.changePassword();
      },
    },
    {
      label: 'Logout',
      icon: 'pi pi-power-off',
      id: 'SEL_LOGOUT',
      command: () => {
        this.logoutConfirmation();
      },
    },
  ];

  constructor(
    public layoutService: LayoutService,
    public credentialsService: CredentialsService,
    private router: Router,
    private translate: TranslateService,
    private environmentCheckup: EnvironmentCheckupService
  ) {}

  redirectToHome(): void {
    const redirectPath = this.environmentCheckup.getLoginRedirectPath(); // Get the correct path based on environment

    // Navigate to the appropriate route based on environment and force a reload
    this.router.navigate([redirectPath]).then(() => {
      // This will force the reloading of the component/module
      this.router.navigate([redirectPath]); // Navigate to the same route to reload
    });
  }

  redirectToSettings(): void {
    this.clearSubmenu.emit(); // Emit event to clear the submenu in the layout
    this.router.navigate(['/settings']); // Navigate to the settings page
  }

  goToHelpCenter(): void {
    const lang = localStorage.getItem('language');

    if (lang === 'de-DE') {
      const helpUrl = 'https://www.amasol.de/service-level-support';

      window.open(helpUrl, '_blank');
    } else {
      const helpUrl = 'https://www.amasol.de/service-level-support';

      window.open(helpUrl, '_blank');
    }
  }

  logoutConfirmation(): void {
    this.open = !this.open;
    this.isViewEnabled = !this.isViewEnabled;
  }

  viewAboutDetails(): void {
    this.isAboutEnabled = !this.isAboutEnabled;
  }

  goToLegal(): void {
    let legalUrl: any;

    if (this.translate.currentLang === 'en-US') {
      legalUrl = 'https://www.amasol.de/en/imprint';
    } else {
      legalUrl = 'https://www.amasol.de/impressum';
    }

    window.open(legalUrl, '_blank');
  }

  private changePassword(): void {
    this.canChangePassword = true;
  }
}
