import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TreeNode } from 'primeng/api';

import { ReportService } from '@app/report/services/report.service';
import { UdfService } from '@app/slm/services/udf.service';

@Component({
  selector: 'app-connector-parameters',
  templateUrl: './connector-parameters.component.html',
  styleUrls: ['./connector-parameters.component.scss'],
})
export class ConnectorParametersComponent implements OnChanges {
  @Output() formDataChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() metricParamsInfo: any;

  dynamicForm!: FormGroup;

  valueSetList: any = [];

  nullData: any = { id: '', key: '', title: ' ' };

  hierarchyList: any = [];

  metricParamValues: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private udfService: UdfService,
    private datePipe: DatePipe,
    private reportService: ReportService
  ) {}

  // ngOnInit(): void {}

  ngOnChanges(): void {
    this.metricParamsInfo.forEach((type: any) => {
      // Hierarchy API call
      if (type.objectFieldType === 'Hierarchy') {
        this.getHierarchyList(type);
      }

      // Value-Set (dropdown list) API call
      if (type.objectFieldType === 'Value Set') {
        this.getValueSetByid(type.valueSetId, type);
      }
    });

    this.buildForm();
  }

  getHierarchyList(hierarchyDetail: any): void {
    const { hierarchyId } = hierarchyDetail;

    this.reportService.getHierarchyFieldData(hierarchyId).subscribe((res: any) => {
      if (res) {
        this.hierarchyList = res.children;
        this.hierarchyList.forEach((node: TreeNode<any>) => {
          this.expandRecursive(node, true);
        });
      }
    });
  }

  getValueSetByid(valueSetIds: number, valueSetData: any): void {
    this.udfService.getValueSet(valueSetIds).subscribe((res: any) => {
      if (res) {
        this.valueSetList[valueSetIds] = res;
        const fieldData = valueSetData;

        if (!fieldData.isMandatory) {
          this.valueSetList[valueSetIds].unshift(this.nullData);
        }

        // Patch value of field type value-set
        if (fieldData.value) {
          this.valueSetList[valueSetIds]?.forEach((element: any) => {
            if (element.title === fieldData.value || element.key === fieldData.value) {
              this.dynamicForm.patchValue({ [fieldData.fieldName]: element.key });
            }
          });
        }
      }
    });
  }

  buildForm(): void {
    const formGroupFields: { [key: string]: any } = {};

    this.metricParamsInfo?.forEach((field: any) => {
      const validators: any = this.setControlsValidators(field);

      const value = field.value ? field.value : null;

      formGroupFields[field.name] = [value, validators];
    });

    this.dynamicForm = this.formBuilder.group(formGroupFields);

    // Subscribe to form value changes after form initialization and patching
    if (this.dynamicForm) {
      this.dynamicForm.valueChanges.subscribe(value => {
        // Emit the payload on form value change
        this.simulateDataAsPerAPI(value);
      });
    }
  }

  // set the validators of form controls
  setControlsValidators(field: any): any {
    const validators: any = [];

    // 1. Required Validator: If the field is mandatory, add a required validator.
    if (field.isMandatory) {
      validators.push(Validators.required);
    }

    // 2. String Validators: Apply minimum and maximum length constraints for string fields.
    if (field.objectFieldType === 'String' || field.objectFieldType === 'Text') {
      if (field.minimumValue) {
        validators.push(Validators.minLength(field.minimumValue));
      }
      if (field.maximumValue) {
        validators.push(Validators.maxLength(field.maximumValue));
      }
    }

    // 3. For number fields, use min and max validators
    if (field.objectFieldType === 'Number') {
      if (field.minimumValue) {
        validators.push(Validators.min(field.minimumValue));
      }
      if (field.maximumValue) {
        validators.push(Validators.max(field.maximumValue));
      }
    }

    // 4. Pattern Validator: Apply a regex pattern validator if specified in the field configuration.
    if (field.patternValidation) {
      validators.push(Validators.pattern(field.patternValidation));
    }

    return validators;
  }

  /**
   * Returns the appropriate view type for a date picker based on the provided object field type.
   *
   * @param {string} objectFieldType - The type of the object field (e.g., 'Month', 'Year', 'Date and Time').
   * @returns {string} - The view type for the date picker ('month', 'year', 'datetime', 'date').
   */
  getViewType(objectFieldType: string): any {
    switch (objectFieldType) {
      case 'Month':
        return 'month';
      case 'Year':
        return 'year';
      default:
        return 'date';
    }
  }

  /**
   * Returns the appropriate date format for a date picker based on the provided object field type.
   *
   * @param {string} objectFieldType - The type of the object field (e.g., 'Month', 'Year', 'Date and Time').
   * @returns {string} - The date format for the date picker (e.g., 'MM', 'yyyy', 'yyyy-MM-dd H:mm:ss', 'yyyy-MM-dd').
   */
  getDateFormat(objectFieldType: string): any {
    switch (objectFieldType) {
      case 'Month':
        return 'mm/yy'; // Ensure this aligns with PrimeNG’s format
      case 'Year':
        return 'yy'; // PrimeNG might expect only the year part
      case 'Date and Time':
        return 'dd-mm-yy HH:mm:ss';
      default:
        return 'dd-mm-yy';
    }
  }

  getIntervalInMilliseconds(interval: Date): number {
    const hours = interval.getHours();
    const minutes = interval.getMinutes();
    const seconds = interval.getSeconds();

    const intervalInMilliseconds = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;

    return intervalInMilliseconds;
  }

  // Define arrays to store metricObjectFields and metricVersionObjectFields
  simulateDataAsPerAPI(value: any): any {
    let field: any;
    const metricParamValues: any[] = [];

    Object.keys(value).forEach(key => {
      const controlValue = value[key];

      // Find the corresponding field data based on fieldName
      const fieldData = this.metricParamsInfo.find((data: any) => data.name === key);

      // If field data is not found, skip this key
      if (!fieldData) {
        return;
      }

      // Create payload object field as it will be mapped as per parent from paramValue data
      if (fieldData.id) {
        field = {
          id: fieldData.metricConnectorId,
          name: fieldData.name,
          metricConnectorParamId: fieldData.id,
        };
      }

      // Set the value based on objectFieldType using switch statement
      switch (fieldData.objectFieldType) {
        case 'Calendar':
          // If it's a Date or Date and Time, transform the value using DatePipe
          field.value = this.datePipe.transform(controlValue, fieldData.format);
          break;
        case 'Interval':
          // Interval, transform the time into miliseconds
          field.value = this.getIntervalInMilliseconds(controlValue);
          break;
        case 'Hierarchy':
          field.hierarchyNodeId = controlValue?.businessId;
          break;
        // For Value Set, use valueSetRowId instead of value
        case 'Value Set':
          field.valueSetRowId = controlValue || null;
          break;
        default:
          // For other types, set the value directly
          field.value = controlValue;
          break;
      }

      metricParamValues.push(field);

      const payload = { metricParamValues };

      this.formDataChanged.emit(payload);
    });
  }

  // submit form
  emitFormData(): void {
    this.formDataChanged.emit(this.dynamicForm.value);
  }

  private expandRecursive(node: TreeNode, isExpand: boolean): void {
    const nodeExpand = node;

    nodeExpand.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
    // to check last node
    if (node.children && node.children.length === 0) {
      nodeExpand.expanded = !nodeExpand.expanded;
    }
  }
}
