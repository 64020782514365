import { DatePipe } from '@angular/common';
import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';

import { ValidationService } from '@app/core/services/validation.service';
import { ConnectorParametersComponent } from '@app/shared/components/connector-parameters/connector-parameters.component';
import { UdfComponent } from '@app/shared/components/udf/udf.component';
import { ErrorBlockService } from '@app/shared/services/error-block.service';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';
import { MetricsService } from '@app/slm/services/metrics.service';
import { UdfService } from '@app/slm/services/udf.service';

@Component({
  selector: 'app-add-metric',
  templateUrl: './add-metric.component.html',
  styleUrls: ['./add-metric.component.scss'],
})
export class AddMetricComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild(UdfComponent) udfComponent!: UdfComponent;

  @ViewChildren(UdfComponent) childComponents!: QueryList<UdfComponent>;

  @ViewChild(ConnectorParametersComponent) connectorParametersComponent!: ConnectorParametersComponent;

  createMetricForm!: FormGroup;

  conditionList: any = [];

  selectedUDF: any;

  UDFList: any;

  formatList: any = [
    {
      id: 1,
      name: 'Number',
      inactive: false,
    },
    {
      id: 5,
      name: 'N/A',
      inactive: false,
    },
    {
      id: 2,
      name: 'Time Interval',
      inactive: true,
    },
    {
      id: 3,
      name: 'Date',
      inactive: true,
    },
    {
      id: 4,
      name: 'Value Substitution',
      inactive: true,
    },
  ];

  subFormatList: any = [];

  periodList: any = [];

  connectorList: any = [];

  contractList: any = [];

  businessServiceList: any = [];

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  isSelectedConnector = false;

  // errorMsgList: any = [];

  selectedDataType!: string;

  metricVersionObjectFields: any;

  UDFVersionList: any;

  metricUDFData: any;

  metricConnectorParamList: any = [];

  connectorParamPayload: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private metricService: MetricsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    public metricPermission: MetricPermissionService,
    private confirmationService: ConfirmationService,
    private udfService: UdfService,
    private cdr: ChangeDetectorRef,
    private errorBlockService: ErrorBlockService
  ) {
    this.createMetricForm = this.formBuilder.group({
      id: ['', [Validators.maxLength(20)]],
      name: ['', [ValidationService.requiredValidator, Validators.maxLength(30)]],
      description: [null, [Validators.maxLength(1000)]],
      metricConnector: [''],
      contract: ['', [ValidationService.requiredValidator]],
      customer: [''],
      provider: [''],
      businessService: ['', [ValidationService.requiredValidator]],
      statement: ['', [ValidationService.requiredValidator, Validators.maxLength(250)]],
      condition: ['', [ValidationService.requiredValidator]],
      targetFormat: [''],
      subTargetFormat: ['', [ValidationService.requiredValidator]],
      period: ['', [ValidationService.requiredValidator]],
      versionName: ['', [Validators.maxLength(20)]],
      target: [],
      expected: [],
      startDate: [''],
      endDate: [''],
      metricVersionStatement: [null, [Validators.maxLength(250)]],
    });
    this.createMetricForm.get('expected')?.disable();
  }

  metricConnectorData(event: any): void {
    if (event.query.length >= 3) {
      const params = { name: event.query };

      this.metricService.getMetricConnectors(params).subscribe((data: any) => {
        if (data) {
          this.connectorList = data;
        } else {
          this.connectorList = [];
        }
      });
    }
  }

  onSelectConnector(): void {
    this.isSelectedConnector = true;

    // get metric connector parameter list
    const connectorId = this.createMetricForm.get('metricConnector')?.value?.id;

    this.metricService.getMetricConnectorParams(connectorId).subscribe((res: any) => {
      if (res) {
        this.metricConnectorParamList = res;
      }
    });
  }

  onResetConnector(): void {
    this.isSelectedConnector = false;
    this.metricConnectorParamList = [];
  }

  contractData(event: any): void {
    if (event.query.length >= 3) {
      const params = { name: event.query };

      this.metricService.getContracts(params).subscribe((data: any) => {
        if (data) {
          this.contractList = data;
        } else {
          this.contractList = [];
        }
      });
    }
  }

  onSelectContract(event: any): void {
    if (this.createMetricForm.get('contract')?.value) {
      this.createMetricForm.get('customer')?.patchValue(event.customerName);
      this.createMetricForm.get('provider')?.patchValue(event.providerName);
    }
  }

  onResetContractField(): void {
    this.createMetricForm.get('customer')?.reset();
    this.createMetricForm.get('provider')?.reset();
  }

  updateSubFormat(): void {
    if (this.createMetricForm.get('targetFormat')?.value) {
      this.createMetricForm.get('subTargetFormat')?.reset();
      this.getSubFormatData();
    }
  }

  saveNewMetric(): void {
    this.errorBlockService.clearErrors();
    if (
      this.createMetricForm.invalid ||
      (this.udfComponent?.dynamicForm?.invalid ?? false) ||
      this.childComponents.some(child => child.dynamicForm.invalid) ||
      (this.connectorParametersComponent?.dynamicForm?.invalid ?? false)
    ) {
      this.createMetricForm.markAllAsTouched();
      this.childComponents.forEach(child => {
        child.dynamicForm.markAllAsTouched();
      });
    } else {
      const startDate = this.datePipe.transform(this.createMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.createMetricForm.get('endDate')?.value, 'yyyy-MM-dd');

      //  createMetricForm is the form group containing all the dynamically added controls
      const basePayload = {
        metricId: this.createMetricForm.get('id')?.value,
        metricName: this.createMetricForm.get('name')?.value,
        description: this.createMetricForm.get('description')?.value,
        metricConnectorId: this.createMetricForm.get('metricConnector')?.value?.id,
        contractId: this.createMetricForm.get('contract')?.value.contractId,
        businessServiceId: this.createMetricForm.get('businessService')?.value.businessId,
        statement: this.createMetricForm.get('statement')?.value,
        conditionId: this.createMetricForm.get('condition')?.value,
        targetFormatId: this.createMetricForm.get('subTargetFormat')?.value,
        periodId: this.createMetricForm.get('period')?.value,
        versionName: this.createMetricForm.get('versionName')?.value,
        target: this.createMetricForm.get('target')?.value,
        expected: this.createMetricForm.get('expected')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        metricVersionStatement: this.createMetricForm.get('metricVersionStatement')?.value,
      };

      const payload = {
        ...basePayload,
        ...(this.metricUDFData && this.metricUDFData.length > 0 ? { metricObjectFields: this.metricUDFData } : {}),
        ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
          ? { metricVersionObjectFields: this.metricVersionObjectFields }
          : {}),
        ...(this.metricConnectorParamList && this.metricConnectorParamList.length > 0
          ? { metricParamValues: this.connectorParamPayload }
          : {}),
      };

      this.metricService.createNewMetric(payload).subscribe(
        (res: any) => {
          if (res) {
            this.metricService.getCreatedMetricData({ res, isNew: true });
            const { versionName } = res.metricVersionList[0];

            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CREATE_METRIC.SUCCESS', {
                metric: res.metricName,
                version: versionName,
              }),
            });
            this.resetForm();
            this.onResetConnector();
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            err.forEach((msg: any) => {
              this.errorBlockService.addError(msg.message);
            });
            // this.errorMsgList = errorList;
          }
        }
      );
    }
  }

  resetForm(): void {
    this.createMetricForm.reset();
    this.updateSubFormat();
    this.minEndDate = new Date();
    // this.errorMsgList = [];
    this.errorBlockService.clearErrors();
    this.businessServiceList = [];
    this.closeClicked.emit(false);
  }

  getConditionData(): void {
    this.metricService.getMetricCondition().subscribe((res: any) => {
      this.conditionList = res;
    });
  }

  // getFormatData(): void {
  //   this.metricService.getTargetFormat().subscribe((res: any) => {
  //     this.formatList = res;
  //   });
  // }

  // sub format list will be filtered based on format value
  getSubFormatData(): void {
    this.metricService.getMetricSubFormat().subscribe((res: any) => {
      this.subFormatList = [];
      const subFormatRes = res;
      const formatValue = this.createMetricForm.get('targetFormat')?.value;

      if (formatValue) {
        const filteredData = subFormatRes.filter((item: any) => item.metricTargetDataTypeId === formatValue);

        this.subFormatList = filteredData;
      }
    });
  }

  metricPeriodData(): void {
    this.metricService.getMetricPeriod().subscribe((res: any) => {
      if (res) {
        this.periodList = res;
      }
    });
  }

  onSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.createMetricForm.get('endDate')?.reset();
  }

  ngOnInit(): void {
    this.getConditionData();
    // this.getFormatData();
    this.metricPeriodData();
    this.getUDFData();
    this.getUDFVersionData();
    this.getOrganizationDetails();
  }

  getOrganizationDetails(): void {
    this.metricService.getMetricTreeData().subscribe((res: any) => {
      this.businessServiceList = res?.children;
      this.businessServiceList.forEach((node: TreeNode<any>) => {
        this.expandRecursive(node, true);
        this.disableRecursive(node);
      });
    });
  }

  publishVersion(): void {
    this.errorBlockService.clearErrors();
    const startDate = this.datePipe.transform(this.createMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.createMetricForm.get('endDate')?.value, 'yyyy-MM-dd');

    const basePayload = {
      metricId: this.createMetricForm.get('id')?.value,
      metricName: this.createMetricForm.get('name')?.value,
      description: this.createMetricForm.get('description')?.value,
      metricConnectorId: this.createMetricForm.get('metricConnector')?.value?.id,
      contractId: this.createMetricForm.get('contract')?.value.contractId,
      businessServiceId: this.createMetricForm.get('businessService')?.value.businessId,
      statement: this.createMetricForm.get('statement')?.value,
      conditionId: this.createMetricForm.get('condition')?.value,
      targetFormatId: this.createMetricForm.get('subTargetFormat')?.value,
      periodId: this.createMetricForm.get('period')?.value,
      versionName: this.createMetricForm.get('versionName')?.value,
      target: this.createMetricForm.get('target')?.value,
      expected: this.createMetricForm.get('expected')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      metricVersionStatement: this.createMetricForm.get('metricVersionStatement')?.value,
    };

    const payload = {
      ...basePayload,
      ...(this.metricUDFData && this.metricUDFData.length > 0 ? { metricObjectFields: this.metricUDFData } : {}),
      ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
        ? { metricVersionObjectFields: this.metricVersionObjectFields }
        : {}),
      ...(this.metricConnectorParamList && this.metricConnectorParamList.length > 0
        ? { metricParamValues: this.connectorParamPayload }
        : {}),
    };

    this.metricService.publishMetricVersion(payload).subscribe(
      (res: any) => {
        if (res) {
          this.metricService.getCreatedMetricData({ res, isNew: true });
          const { versionName } = res.metricVersionList[0];

          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CREATE_METRIC.METRIC_PUBLISH', {
              metric: res.metricName,
              version: versionName,
            }),
          });
          this.resetForm();
          this.onResetConnector();
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          err.forEach((msg: any) => {
            this.errorBlockService.addError(msg.message);
          });
          // this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmPublish(event: Event): void {
    this.errorBlockService.clearErrors();

    if (!this.createMetricForm.get('startDate')?.value || !this.createMetricForm.get('endDate')?.value) {
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      this.errorBlockService.addError(msg.message);
      this.createMetricForm.markAllAsTouched();
    }
    if (
      this.createMetricForm.invalid ||
      (this.udfComponent?.dynamicForm?.invalid ?? false) ||
      this.childComponents.some(child => child.dynamicForm.invalid) ||
      (this.connectorParametersComponent?.dynamicForm?.invalid ?? false)
    ) {
      this.createMetricForm.markAllAsTouched();
      this.childComponents.forEach(child => {
        if (child.dynamicForm.invalid) {
          child.dynamicForm.markAllAsTouched();
        }
      });
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion();
        },
      });
    }
  }

  getTagetValue(): void {
    if (this.createMetricForm.get('target')?.value) {
      this.createMetricForm.get('expected')?.enable();
    } else {
      this.createMetricForm.get('expected')?.disable();
      this.createMetricForm.patchValue({ expected: null });
    }
  }

  onUDFSelection(event: any): void {
    this.udfService.getUDFType(event.value);
  }

  onFormDataChanged(formData: any): void {
    // Check if either 'metricObjectFields' or 'metricVersionObjectFields' exist and have length > 0
    ['metricObjectFields', 'metricVersionObjectFields'].forEach(field => {
      if (formData[field] && formData[field].length > 0) {
        const filteredData = formData[field].filter((obj: any) => {
          const hasValue =
            Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;
          const hasValueSetKey =
            Object.prototype.hasOwnProperty.call(obj, 'valueSetKey') &&
            obj.valueSetKey !== null &&
            obj.valueSetKey !== undefined;

          return hasValue || hasValueSetKey;
        });

        // Assign the filtered data to the correct property
        if (field === 'metricObjectFields') {
          this.metricUDFData = filteredData;
        } else if (field === 'metricVersionObjectFields') {
          this.metricVersionObjectFields = filteredData;
        }
      }
    });
  }

  /** UDF API  */

  getUDFData(): void {
    const params = {
      type: 'Metric',
    };

    this.metricService.getUDFInfo(params).subscribe((res: any) => {
      if (res && res.length > 0) {
        this.UDFList = res || [];
        this.udfService.getUDFType(res);
        this.cdr.detectChanges(); // Trigger change detection
      }
    });
  }

  getUDFVersionData(): void {
    const params = {
      type: 'MetricVersion',
    };

    this.metricService.getUDFInfo(params).subscribe((res: any) => {
      if (res && res.length > 0) {
        this.UDFVersionList = res || [];
        this.udfService.getUDFType(res);
        this.cdr.detectChanges(); // Trigger change detection
      }
    });
  }

  onParameterFormSubmit(formData: any): void {
    // Check if either 'metricParamsValue' exist and have length > 0
    ['metricParamValues'].forEach(field => {
      if (formData[field] && formData[field].length > 0) {
        const filteredData = formData[field].filter((obj: any) => {
          const hasValue =
            Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;
          const hasValueSetRowId =
            Object.prototype.hasOwnProperty.call(obj, 'valueSetRowId') &&
            obj.valueSetRowId !== null &&
            obj.valueSetRowId !== undefined;

          const hasHierarchyNodeId =
            Object.prototype.hasOwnProperty.call(obj, 'hierarchyNodeId') &&
            obj.hierarchyNodeId !== null &&
            obj.hierarchyNodeId !== undefined;

          return hasValue || hasValueSetRowId || hasHierarchyNodeId;
        });

        // Assign the filtered data to the correct property
        if (field === 'metricParamValues') {
          this.connectorParamPayload = filteredData;
        }
      }
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean): void {
    const nodeExpand = node;

    nodeExpand.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
    // to check last node
    if (node.children && node.children.length === 0) {
      nodeExpand.expanded = !nodeExpand.expanded;
    }
  }

  private disableRecursive(node: any): void {
    if (node.childLevelName !== 'Business Service') {
      const nodeSelected = node;

      nodeSelected.selectable = false;
      node.children.forEach((childNode: any) => {
        this.disableRecursive(childNode);
      });
    }
  }
}
